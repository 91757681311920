import { axios } from '@/_modules/axios';
import {
  BasePagination,
  BaseResponse,
  PlayerPosition,
  IPosition
} from '@/_modules/types';

const list = async (
  type: 'athlete' | 'staff' | 'team'
): Promise<BaseResponse<BasePagination<PlayerPosition[]>>> => {
  let url = '';
  switch (type) {
    case 'athlete':
      url = '/api/v1/setting/global/positions/player';
      break;
    case 'staff':
      url = '/api/v1/setting/global/positions/staff';
      break;
    case 'team':
      url = '/api/v1/global/position/staff/all';
      break;
    default:
      break;
  }
  const { data } = await axios.get<
    BaseResponse<BasePagination<PlayerPosition[]>>
  >(url);
  return data;
};

const listPosition = async (): Promise<BaseResponse<IPosition[]>> => {
  const { data } = await axios.get<BaseResponse<IPosition[]>>(
    '/api/v1/global/position/staff/all'
  );
  return data;
};

const listPositionPlayer = async (): Promise<BaseResponse<IPosition[]>> => {
  const { data } = await axios.get<BaseResponse<IPosition[]>>(
    '/api/v1/global/position/player/all'
  );
  return data;
};

export const positionService = {
  list,
  listPosition,
  listPositionPlayer
};
