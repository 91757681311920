import { axios } from '@/_modules/axios';
import {
  BaseResponse,
  BaseSingleResponse,
  INationality
} from '@/_modules/types';

const url = '/api/v1/global';

const getNationality = async () => {
  const { data } = await axios.get(`${url}/nationality/all`);
  return data;
};

const getDepartment = async () => {
  const { data } = await axios.get(`${url}/position/department/all`);
  return data;
};

const getCompetitionAll = async () => {
  const { data } = await axios.get('/api/v1/global/competition/all');
  return data;
};

const getStadiumAll = async () => {
  const { data } = await axios.get('/api/v1/global/stadium/all');
  return data;
};

const getCountryAll = async () => {
  const { data } = await axios.get('/api/v1/global/country/all');
  return data;
};

const getProvinceAll = async () => {
  const { data } = await axios.get('/api/v1/global/province/all');
  return data;
};
export const dropdownService = {
  getNationality,
  getDepartment,
  getCompetitionAll,
  getStadiumAll,
  getCountryAll,
  getProvinceAll
};
