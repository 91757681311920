
import useNationalityRepositiories from "@/_composables/useNationalityRepositories";
import { SelectTypes } from "ant-design-vue/es/select";
import { defineComponent, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  emits: ["selected"],

  setup(props, { emit }) {
    const options = ref<any[]>([]);
    const store = useStore();

    const { getNationalityRepositiories } = useNationalityRepositiories();
    // const options = ref<SelectTypes["options"]>([
    //   { value: "jack", label: "Jack" },
    //   { value: "lucy", label: "Lucy" },
    //   { value: "tom", label: "Tom" },
    // ]);
    const handleChange = (value: string) => {
      emit("selected", value);
    };
    const handleBlur = () => {
      // console.log("blur");
    };
    const handleFocus = () => {
      // console.log("focus");
    };
    const filterOption = (input: string, option: any) => {
      return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    const checkLanguage = async() => {
      const national = await getNationalityRepositiories();
      if (national?.length) {
        options.value =
          national?.map((ele: any) => {
            return {
              value: ele.country_native1_name_th,
              label:
                store.state.currentLanguage === "th"
                  ? ele.country_native1_name_th
                  : ele.country_native1_name_en,
              // label: {
              //   th:ele.country_native1_name_th,
              //   en:ele.country_native1_name_en
              // },
            };
          }) ?? [];
      }
    };

    onMounted(() => {
      checkLanguage();
    });

    watch(
      () => store.state.currentLanguage,
      (newValue) => {
              checkLanguage();
      }
    );
    return {
      store,
      value: ref<string | undefined>(undefined),
      filterOption,
      handleBlur,
      handleFocus,
      handleChange,
      options,
    };
  },
});
