
import useNationalityRepositiories from "@/_composables/useNationalityRepositories";

import { SelectTypes } from 'ant-design-vue/es/select'
import { onMounted, defineComponent, reactive, ref, toRefs, watch } from 'vue'
import useRegionRepositories from '@/_composables/useRegionRepositories'
import { useStore } from "vuex";

export default defineComponent({
  emits: ["selected"],
  setup (props, { emit }) {
    // const { provinces } = useRegionRepositories()
        const { getProvinceRepositiories } = useNationalityRepositiories()
        const options = ref<any[]>([])
    const store = useStore();

    const handleChange = (value: string) => {
      emit('selected', value)
    }
    const handleBlur = () => {
      // console.log('blur')
    }
    const handleFocus = () => {
      // console.log('focus', state.options)
    }
    const filterOption = (input: string, option: any) => {
      return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }

const checkLanguage = async () =>{
      const province = await getProvinceRepositiories();

      if(province?.length){
        options.value =
          province?.map((ele: any) => {
            return {
              label:
                store.state.currentLanguage === "th"
                  ? ele.province_name_th
                  : ele.province_name_en,
              // label: {
              //   th:ele.province_name_th,
              //   en:ele.province_name_en
              // },
              // value: {
              //   th:ele.province_name_th,
              //   en:ele.province_name_en
              // },
              value:ele.id
            };
          }) ?? [];
      }
    }
    onMounted(() => {
      checkLanguage();
    });

    watch(
      () => store.state.currentLanguage,
      (newValue) => {
              checkLanguage();
      }
    );
    return {
      store,
      value: ref<string | undefined>(undefined),
      filterOption,
      handleBlur,
      handleFocus,
      handleChange,
      options
    }
  }
})
