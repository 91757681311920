import { ref, watch, Ref } from 'vue'
import { useRequest } from 'vue-request'
import {
  BaseResponse,
  TeamDetail,
  BasePagination,
  PlayerPosition,
  ITeam,
  PositionType
} from '@/_modules/types'

import { positionService } from '@/_services/master/positions.service'
interface ReturnType {
  repositories: Ref<PlayerPosition[]>;
  loading: Ref<boolean>;
  // getTeamRepositories: (type: TeamQueryDto) => Promise<ITeam[] | null>;
  // getTeamRepository: (id: number) => Promise<ITeam | null>;
}
export default function usePositionRepositories (q: Ref<PositionType>): ReturnType {
  const repositories = ref()
  const { run: getPositionRepositories, loading } = useRequest(
    positionService.list,
    {
      manual: true,
      formatResult: ({ data }) => {
        return data?.data ?? []
      },
      onSuccess: (formattedData) => {
        repositories.value = formattedData
      }
    }
  )
  watch(() => q?.value, () => {
    if (q?.value) {
      getPositionRepositories(q.value as any)
    }
  }, { immediate: true })

  return {
    repositories,
    loading
  }
}
