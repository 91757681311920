import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select = _resolveComponent("a-select")!

  return (_openBlock(), _createBlock(_component_a_select, {
    value: _ctx.value,
    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
    "show-search": "",
    placeholder: _ctx.store.state.currentLanguage === 'th'?'ชื่อจังหวัด':'Province',
    class: "w-100",
    size: "large",
    options: _ctx.options,
    onFocus: _ctx.handleFocus,
    onBlur: _ctx.handleBlur,
    onChange: _ctx.filterOption
  }, {
    option: _withCtx(({ label: label }) => [
      _createTextVNode(_toDisplayString(label), 1)
    ]),
    _: 1
  }, 8, ["value", "placeholder", "options", "onFocus", "onBlur", "onChange"]))
}