
import useNationalityRepositiories from "@/_composables/useNationalityRepositories";
import { useStore } from "vuex";

import { defineComponent, onMounted, ref, watch } from 'vue'
export default defineComponent({
  emits: ['selected'],
  setup (props, { emit }) {
        const options = ref<any[]>([])
    const store = useStore();

    const { getCountryRepositiories } = useNationalityRepositiories()
    const handleChange = (value: string) => {
      emit('selected', value)
    }
    const handleBlur = () => {
      // console.log('blur')
    }
    const handleFocus = () => {
      // console.log('focus')
    }
    const filterOption = (input: string, option: any) => {
      return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }

        const checkLanguage = async() => {
const country = await getCountryRepositiories();

      if(country?.length){
        options.value =
          country?.map((ele: any) => {
            return {
              value: ele.country_id,
              // label: {
              //   th:ele.country_native1_name_th,
              //   en:ele.country_native1_name_en
              // },
              label:
                store.state.currentLanguage === "th"
                  ? ele.country_native1_name_th
                  : ele.country_native1_name_en

            };
          }) ?? [];
      }
        }
    onMounted(async () =>{
      checkLanguage()
    })
    // watch(() => countries.value, (newVal) => {
    //   console.log('newVal',newVal);
    //   if (newVal?.length) {
    //     options.value = countries?.value?.map(ele => {
    //       return { label: ele.country_native1_name_th, value: '' + ele.country_id }
    //     }) ?? []
    //   }
    // })
    watch(
      () => store.state.currentLanguage,
      (newValue) => {
              checkLanguage();
      }
    );
    return {
      store,
      value: ref<string | undefined>(undefined),
      filterOption,
      handleBlur,
      handleFocus,
      handleChange,
      options
    }
  }
})
