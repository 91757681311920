<template>
  <a-select
    :value="value"
    show-search
    :placeholder="
      store.state.currentLanguage === 'th' ? 'ชื่อสนามการแข่งขัน' : 'Stadium'
    "
    class="w-100"
    size="large"
    :options="options"
    @change="filterOption"
    :onChange="handleChange"
  >
    <template #option="{ label }">{{ label }} </template>
  </a-select>
</template>

<script>
import { defineComponent, onMounted, ref, watch } from "vue";
import { dropdownService } from "@/_services/master/dropdown.service";
import { useRequest } from "vue-request";
import { useStore } from "vuex";

export default {
  name: "stadium-selector",
  emits: ["selected"],
  props: ["value"],
  setup(props, { emit }) {
    const { run: getStadiumAll } = useRequest(dropdownService.getStadiumAll, {
      manual: true,
    });
    const store = useStore();

    const options = ref([]);

    const onSetOptions = (arr) => {
      options.value =
        arr.map((ele) => {
          return {
             label:
                store.state.currentLanguage === "th"
                  ? ele.setting_stadium_name_th
                  : ele.setting_stadium_name_en,
            // label: {
            //   th: ele.setting_stadium_name_th,
            //   en: ele.setting_stadium_name_en,
            // },
            value: ele.id,
          };
        }) ?? [];
    };

    const handleChange = (value) => {
      emit("selected", value);
    };

    const filterOption = (input, option) => {
      return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    onMounted(async () => {
      try {
        const { data } = await getStadiumAll();
        onSetOptions(data.stadium);
      } catch (error) {
      } finally {
      }
    });

    watch(
      () => store.state.currentLanguage,
      async (newValue) => {
        try {
          const { data } = await getStadiumAll();
          onSetOptions(data.stadium);
        } catch (error) {
        } finally {
        }
      }
    );
    return {
      store,
      options,
      handleChange,
      filterOption,
    };
  },
};
</script>

<style>
</style>