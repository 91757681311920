
import useNationalityRepositiories from "@/_composables/useNationalityRepositories";
import { useStore } from "vuex";

import { SelectTypes } from "ant-design-vue/es/select";
import { defineComponent, ref, onMounted, watch } from "vue";
export default defineComponent({
  emits: ["selected"],
  setup(props, { emit }) {
    const store = useStore();

    const options = ref<any[]>([]);
    const { getDepartmentRepositiories } = useNationalityRepositiories();

    const handleChange = (value: string) => {
      emit("selected", value);
    };
    const handleBlur = () => {
      // console.log('blur')
    };
    const handleFocus = () => {
      // console.log('focus')
    };
    const filterOption = (input: string, option: any) => {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };
    const checkLanguage = async () => {
      const national = await getDepartmentRepositiories();
      if (national?.length) {
        options.value =
          national?.map((ele: any) => {
            return {
              // label: {
              //   th:ele.global_config_department_name_th,
              //   en:ele.global_config_department_name_en,

              // },
              label:
                store.state.currentLanguage === "th"
                  ? ele.global_config_department_name_th
                  : ele.global_config_department_name_en,
              value: ele.id,
            };
          }) ?? [];
      }
    };

    onMounted(() => {
      checkLanguage();
    });
    watch(
      () => store.state.currentLanguage,
      (newValue) => {
        checkLanguage();
      }
    );

    return {
      store,
      value: ref<string | undefined>(undefined),
      filterOption,
      handleBlur,
      handleFocus,
      handleChange,
      options,
    };
  },
});
