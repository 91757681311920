
import { SelectTypes } from "ant-design-vue/es/select";
import { defineComponent, onMounted, ref, watch } from "vue";
import usePositionRepositories from "@/_composables/usePositionRepositories";
import { useRequest } from "vue-request";
import { positionService } from "@/_services/master/positions.service";
import { useStore } from "vuex";

export default defineComponent({
  emits: ["selected"],
  props: ["positionStatus", "type"],
  model: {
    props: "positionStatus",
  },

  setup(props, { emit }) {
    const store = useStore();

    const { repositories: positions } = usePositionRepositories(
      ref(props.type)
    );
    const { run: listPosition } = useRequest(positionService.listPosition, {
      manual: true,
    });

    const { run: listPositionPlayer } = useRequest(
      positionService.listPositionPlayer,
      {
        manual: true,
      }
    );
    onMounted(async () => {
      try {
        if (props.type === "position-staff") {
          const { data }: any = await listPosition();
          onSetOptions(data.position);
        } else {
          const { data }: any = await listPositionPlayer();
          onSetOptions(data.position);
        }
      } catch (error) {
      } finally {
      }
    });

    const options = ref<any[]>([]);
    const handleChange = (value: string) => {
      emit("selected", value);
    };
    const handleBlur = () => {
      // console.log("blur");
    };
    const handleFocus = () => {
      // console.log("focus");
    };
    const filterOption = (input: string, option: any) => {
      return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };
    const onSetOptions = (arr: any) => {
      options.value =
        arr.map((ele: any) => {
          return {
            label:
              store.state.currentLanguage === "th"
                ? ele.global_config_position_name_th
                : ele.global_config_position_name_en,
            // label: {
            //   th:ele.global_config_position_name_th,
            //   en:ele.global_config_position_name_en,
            // },
            value: ele.id,
            tag: ele?.global_config_position_abbreviation || null,
          };
        }) ?? [];
    };
    watch(
      () => positions.value,
      (newVal) => {
        if (newVal?.length > 0 && !props.type) {
          onSetOptions(positions?.value);
        } else {
          options.value = [];
        }
      }
    );

    watch(
      () => store.state.currentLanguage,
      async (newVal) => {
        try {
          if (props.type === "position-staff") {
            const { data }: any = await listPosition();
            onSetOptions(data.position);
          } else {
            const { data }: any = await listPositionPlayer();
            onSetOptions(data.position);
          }
        } catch (error) {
        } finally {
        }
      }
    );

    return {
      store,
      value: ref<string | undefined>(undefined),
      filterOption,
      handleBlur,
      handleFocus,
      handleChange,
      options,
    };
  },
});
