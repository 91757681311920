import { ref, watch, Ref, onMounted } from 'vue'
import { useRequest } from 'vue-request'
import { INationality, PositionType } from '@/_modules/types'

import { dropdownService } from '@/_services/master/dropdown.service'

interface ReturnType {
    getNationalityRepositiories: () => Promise<any | undefined>
    getDepartmentRepositiories: () => Promise<any | undefined>
    getCountryRepositiories: () => Promise<any | undefined>
    getProvinceRepositiories: () => Promise<any | undefined>
}

export default function useNationalityRepositiories(): ReturnType {
    const { run: getNationalityRepositiories } = useRequest(
        dropdownService.getNationality,
        {
            manual: true,
            formatResult: ({ data }) => {
                return data?.nationality ?? []
            },

        }
    )

    const { run: getDepartmentRepositiories } = useRequest(
        dropdownService.getDepartment,
        {
            manual: true,
            formatResult: ({ data }) => {
                return data?.department ?? []
            },

        }
    )

    const { run: getCountryRepositiories } = useRequest(
        dropdownService.getCountryAll,
        {
            manual: true,
            formatResult: ({ data }) => {
                return data?.country ?? []
            },

        }
    )

    const { run: getProvinceRepositiories } = useRequest(
        dropdownService.getProvinceAll,
        {
            manual: true,
            formatResult: ({ data }) => {
                return data?.province ?? []
            },

        }
    )
    return {
        getNationalityRepositiories,
        getDepartmentRepositiories,
        getCountryRepositiories,
        getProvinceRepositiories
    }
}