import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0c69ef72"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select = _resolveComponent("a-select")!

  return (_openBlock(), _createBlock(_component_a_select, {
    value: _ctx.value,
    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
    "show-search": "",
    placeholder: 
      _ctx.store.state.currentLanguage === 'th' ? 'ตำแหน่งการเล่น' : 'Position'
    ,
    class: "w-100",
    size: "large",
    options: _ctx.options,
    onFocus: _ctx.handleFocus,
    onBlur: _ctx.handleBlur,
    onChange: _ctx.filterOption
  }, {
    option: _withCtx(({ value: val, label, tag }) => [
      tag
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: "badge d-inline-flex align-items-center justify-content-center rounded-circle bg-primary text-white me-1",
            role: "img",
            "aria-label": val
          }, _toDisplayString(tag), 9, _hoisted_1))
        : _createCommentVNode("", true),
      _createTextVNode(_toDisplayString(label), 1)
    ]),
    _: 1
  }, 8, ["value", "placeholder", "options", "onFocus", "onBlur", "onChange"]))
}